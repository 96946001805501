import { type LString } from '@web-solutions/module-localization';

export enum PriceAfterTrialType {
  WEEK = 'week',
  FULL = 'full',
};

export enum ButtonPlacementType {
  FIXED = 'fixed',
  PLANS = 'plans',
  PAYMENT = 'payment',
};

export enum ButtonBehavior {
  SCROLL = 'scroll',
  MODAL = 'modal',
  NEXT = 'next',
};

export enum CardFormLayout {
  STANDART = 'standart',
  COMPACT = 'compact',
}

export type ButtonConfig = {
  behavior?: ButtonBehavior,
  title?: LString,
  uppercaseTitle?: boolean,
  enabledFlareAnimation?: boolean,
}

export type InterButtonConfig<SummarySection = string> = ButtonConfig & {
  section: SummarySection,
  priceTitle?: LString,
}
