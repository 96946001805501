
import { tm } from '@web-solutions/module-localization';
import type { PriceTypes } from '@web-solutions/core/constants/remote-config';
import type { ProductDetails } from '@web-solutions/core/store/billing/selectors';

const priceTypes = {
  today: 'priceText',
  week: 'weekPrice',
  day: 'dayPrice',
} as const

interface Params {
  activeProduct: ProductDetails,
  totalPriceType: PriceTypes
}

export const getActualPrices = ({ activeProduct, totalPriceType }: Params) => {
  const {
    textLineThrough,
    isTrial,
    trialPrice,
  } = activeProduct;

  const priceText = activeProduct[priceTypes[totalPriceType]];

  return {
    price: priceText,
    fullPrice: tm(textLineThrough, '', activeProduct),
    actualPrice: isTrial ? trialPrice : priceText,
  }
}
