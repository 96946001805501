import i18next from 'i18next';
import { TransProps } from 'react-i18next';

import { t as baseT, T as BaseT } from '@web-solutions/module-localization';

import en from './en.json';

const ns = '@web-solutions/face-reading';

export function initLocalization(overriding?: any) {
  i18next.addResourceBundle('en', ns, en, true, false);
  if (overriding) {
    i18next.addResourceBundle('en', ns, overriding, true, true);
  }
}

type TProps = Omit<TransProps<any>, "i18n" | "i18nKey">

export const T = ({ k, tOptions, ...other }: { k: string, } & TProps) => {
  return <BaseT
    k={k}
    tOptions={tOptions}
    //@ts-ignore
    ns={ns}
    {...other}
  />
}

export function t(key: string, options?: object): string {
  return baseT(ns + ':' + key, options);
}
