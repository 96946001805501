import type { FC } from 'react';
import classNames from 'classnames';

import classes from './style.module.scss';

interface Props {
  text: string,
  isCreateAccountStep: boolean,
  status: 'current' | 'next' | 'prev',
}

export const DefaultProgress: FC<Props> = ({ text, isCreateAccountStep, status }) => (
  <div
    className={classNames(classes.status,
      classes[status],
      { [classes.gap]: isCreateAccountStep }
    )}
  >
    <div className={classes.point}>
      <div className={classes.center} />
    </div>
    <div>{text}</div>
  </div>
);
