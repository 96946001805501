import classNames from "classnames"

import { T } from "@web-solutions/module-localization";

import classes from '../../style.module.scss';

import { tKey } from './constants'
import { TotalPrice } from './total-price'

export interface SimpleViewProps {
  fullPrice: string;
  discountPrice: string;
  discount?: number;
  actualPrice: string;
  showPrice: boolean;
  mode?: 'default' | 'short';
}

export const SimpleView: React.FC<SimpleViewProps> = ({
  fullPrice,
  discountPrice,
  discount,
  actualPrice,
  mode,
  showPrice,
}) => (
  <>
    {mode !== 'short' && <>
      <div className={classNames(classes.row, classes.with_margin)}>
        <p className={classes.aboutItemGrayText}>
          <T k={`${tKey}.personal_plan`} />
        </p>
        <p className={classes.aboutItemGrayText}>
          {fullPrice}
        </p>
      </div>
      <div className={classNames(classes.row, classes.with_border)}>
        <p className={classes.aboutItemGrayText}>
          <T k={`${tKey}.introductory_discount`} tOptions={{ discount }} />
        </p>
        <p className={classNames(classes.aboutItemGrayText, classes.discount_text)}>
          -{discountPrice}
        </p>
      </div>
    </>
    }
    {showPrice && <TotalPrice mode={mode} actualPrice={actualPrice} fullPrice={fullPrice} />}
    {mode !== 'short' &&
      <div className={classNames(classes.row, classes.with_border)}>
        <p className={classes.aboutItemDiscountText}>
          <T k={`${tKey}.total_discount`} tOptions={{ discount, amount: discountPrice }} />
        </p>
      </div>
    }
  </>
)
