import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';

import Analytics from '@web-solutions/module-analytics';
import { tm, T } from '@web-solutions/module-localization';

import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import { Title, Section } from '@web-solutions/core/ui-elements';

import TrialReminder from '@web-solutions/core/payment/components/trial-reminder';
import PaymentDescription from '@web-solutions/core/payment/components/payment-description';
import { ProductDetails, selectSpecialOfferEndDate } from '@web-solutions/core/store/billing/selectors';

import { PlanType } from 'core/constants/remote-config';

import { FixedButton, type FixedButtonProps } from '../fixed-button';
import InjectedPaymentMethodSwitch from '../injected-payment-method/components/switch';

import { PaymentSafe } from '../../containers/payment-safe';

import { PolicyText } from './policy-text';
import { Item } from './item';
import { Tile } from './tile';
import { PdfItem } from './pdf-item';
import { Feature } from './feature';

import classes from './style.module.scss';
import { SinglePlan } from './single-plan';

interface PlansProps {
  activeProduct: ProductDetails,
  products: ProductDetails[],
  showTerms?: boolean,
  onProductClick: (p: ProductDetails) => void,
  onFixedButtonClick?: FixedButtonProps['onClick'],
  onPayPalSubmit?: FixedButtonProps['onPayPalSubmit'],
  subtitle?: React.ReactNode,
}

export const Plans: React.FC<PlansProps> = ({
  activeProduct,
  products,
  showTerms = true,
  onProductClick,
  onFixedButtonClick,
  onPayPalSubmit,
  subtitle,
}) => {
  const plansSectionId = 'plans';
  const productsListId = 'products-list';

  const {
    policyLinks,
    buttonsConfigs,
    productsTitle,
    showPaymentSafeAtPlans,
    isInjectedPaymentMethodOnPage,
    isTrialPeriodReminder,
    specialOffer,
    planType,
    plansFeatures,
    showTermsAtPlans,
  } = useRemoteConfig();

  const specialOfferEndDate = useSelector(selectSpecialOfferEndDate);

  const handleItemClick = useCallback(
    (selectedProduct: ProductDetails) => {
      Analytics.trackEvent('product', 'selected', { selectedProductId: selectedProduct.id });
      onProductClick(selectedProduct);
    },
    [onProductClick],
  );

  const renderItems = useCallback(
    (type: PlanType) => products.map((product) => {
      switch (type) {
        case PlanType.HORIZONTAL:
          return (
            <Tile
              key={product.id}
              isActive={activeProduct.id === product.id}
              onClick={handleItemClick}
              product={product}
            />
          );
        case PlanType.PDF:
          return (
            <PdfItem
              key={product.id}
              isActive={activeProduct.id === product.id}
              onClick={handleItemClick}
              product={product}
            />
          )
        default:
          return (
            <Item
              key={product.id}
              isActive={activeProduct.id === product.id}
              onClick={handleItemClick}
              product={product}
            />
          )
      }
    }),
    [products, activeProduct.id, handleItemClick],
  );

  const renderPlanFeatures = useCallback(() => plansFeatures.map((feature) => (
    <Feature text={feature} />
  )), [plansFeatures])

  const title = specialOfferEndDate
    ? <T k={'core.special_offer.products'} tOptions={{ discount: specialOffer.discount }} />
    : tm(productsTitle, '');

  const classContainer = classNames(classes.container, { [classes.horizontal]: planType === PlanType.HORIZONTAL });

  return (
    <Section className={classNames(classes.wrap)} id={plansSectionId}>
      {products.length === 1 && planType === PlanType.SINGLE ?
        <SinglePlan product={products[0]} />
        :
        <>
          {!!title && <Title level="h2">{title}</Title>}
          {!!plansFeatures?.length && <div className={classes.plansFeatures}>{renderPlanFeatures()}</div>}
          {subtitle}
          <div className={classContainer}>
            <ul className={classes.productsList} id={productsListId}>{renderItems(planType)}</ul>
          </div>
        </>
      }

      {
        isInjectedPaymentMethodOnPage && (
          <InjectedPaymentMethodSwitch />
        )
      }

      {
        (isTrialPeriodReminder && !activeProduct.isOneTimePurchase) && <TrialReminder className={classes.reminder} />
      }

      {!!onFixedButtonClick && !!onPayPalSubmit &&
        <FixedButton
          activeProduct={activeProduct}
          buttonsConfigs={buttonsConfigs}
          onClick={onFixedButtonClick}
          id={productsListId}
          onPayPalSubmit={onPayPalSubmit}
        />
      }
      <PolicyText policyLinks={policyLinks} />
      {
        (showTerms && showTermsAtPlans) ? (
          <PaymentDescription
            activeProduct={activeProduct}
            isShort
          />
        ) : null
      }

      {showPaymentSafeAtPlans ? <PaymentSafe isModal={false} /> : null}

    </Section>
  );
};
