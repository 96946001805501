import React from 'react';
import classNames from 'classnames';
import Lottie from 'lottie-react-web';

import { Button, LinkButton } from '@web-solutions/core/ui-elements';
//@ts-ignore
import { checkIsAndroidTikTokBrowser } from '@web-solutions/core/utils/common';

import { t } from '../localization';

import { ReactComponent as FaceIcon } from './images/face.svg';
import scan from './animations/scan.json';
//@ts-ignore
import video from './animations/Video.mp4'

import classes from './style.module.scss';
import { IMAGES } from './images';

const tKey = 'welcome';

interface FaceReadingWelcomeProps {
  isSkipAvailable?: boolean;
  isUploadAvailable?: boolean;
  type?: 'image' | 'static' | 'video',
  onCaptureClick: () => void;
  onUploadClick: () => void;
  onSkipClick: () => void;
  welcomeInstructions?: boolean;
}

export const FaceReadingWelcome: React.FC<FaceReadingWelcomeProps> = ({
  isSkipAvailable,
  isUploadAvailable,
  type = 'image',
  onCaptureClick,
  onUploadClick,
  onSkipClick,
  welcomeInstructions
}) => {
  const isFixedButton = isSkipAvailable || isUploadAvailable;
  const instructions = t(`${tKey}.instructions`, { returnObjects: true }) as any as string[];

  const withAnimation = type !== 'image'

  const isTikTok = checkIsAndroidTikTokBrowser()

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>{t(`${tKey}${withAnimation ? '.animation' : ''}.title`)}</div>
      {withAnimation ?
        <div className={classes.animation}>
          {type === 'video' ?
            <>
              <video autoPlay loop muted playsInline>
                <source src={video} type="video/mp4" />
              </video>
              <Lottie
                options={{ animationData: scan }}
                style={{ position: 'absolute', zIndex: 100, top: 0, left: 0 }}
              />
            </>
            :
            <img src={IMAGES.FACE.src} srcSet={IMAGES.FACE.srcSet} alt="face" />
          }
        </div>
        :
        <FaceIcon
          className={classes.image}
        />
      }
      {welcomeInstructions &&
        <div className={classes.instructions}>
          {
            instructions.map((item, index) => (
              <p className={classes.instructions_item_text} key={index}><span >{index + 1}. </span>{item}</p>
            ))
          }
        </div>
      }
      {
        !welcomeInstructions &&
        <div className={classes.subtitle}>{t(`${tKey}${withAnimation ? '.animation' : ''}.subtitle`)}</div>
      }
      <div className={classes.footer}>
        {isTikTok ?
          <>
            {isUploadAvailable && <Button
              flare
              title={t(`${tKey}.upload`)}
              onClick={onUploadClick}
              iconRight
              className={classNames({ [classes.button_fixed]: !isFixedButton })}
            />}
            {
              isSkipAvailable && (
                <LinkButton className={classNames(classes.link, classes.skip)} onClick={onSkipClick}>
                  {t(`${tKey}.skip`)}
                </LinkButton>
              )
            }
            <LinkButton className={classes.link} onClick={onCaptureClick}>
              {t(`${tKey}${withAnimation ? '.animation' : ''}.button_title`)}
            </LinkButton>
          </>
          :
          <>
            <Button
              flare
              title={t(`${tKey}${withAnimation ? '.animation' : ''}.button_title`)}
              onClick={onCaptureClick}
              iconRight
              className={classNames({ [classes.button_fixed]: !isFixedButton })}
            />
            {
              isSkipAvailable && (
                <LinkButton className={classNames(classes.link, classes.skip)} onClick={onSkipClick}>
                  {t(`${tKey}.skip`)}
                </LinkButton>
              )
            }
            {
              isUploadAvailable && (
                <LinkButton className={classes.link} onClick={onUploadClick}>
                  {t(`${tKey}.upload`)}
                </LinkButton>
              )
            }
          </>}
      </div>
    </div>
  );
};
