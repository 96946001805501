import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { t } from '@web-solutions/module-localization';

import { remoteConfigSelector } from 'core/store/remote-config/selectors';
import { setTrialsLeftCounter } from 'core/store/billing/actions';
import { selectTrialsLeftCounter } from 'core/store/billing/selectors';

import classes from './style.module.scss';

export enum TrialsLeftType {
  BADGE_BUTTON = 'badge_button',
  BADGE_PRODUCT = 'badge_product',
  TEXT = 'text'
}
interface Props {
  type: TrialsLeftType;
}

const tKey = 'core.trials_left';

let isRunning = false;

const TrialsLeft: React.FC<Props> = ({ type }) => {
  const { trialsLeft } = useSelector(remoteConfigSelector);
  const counter = useSelector(selectTrialsLeftCounter);

  const dispatch = useDispatch();
  const minTrialLeftCount = 3;

  useEffect(() => {
    if (counter > minTrialLeftCount && !isRunning) {
      isRunning = true
      const timerId = setTimeout(() => {
        dispatch(setTrialsLeftCounter());
      }, trialsLeft.time);

      return () => {
        isRunning = false
        clearTimeout(timerId)
      };
    }
  }, [counter, trialsLeft.time, dispatch]);

  const shouldRenderBadge = type === TrialsLeftType.BADGE_BUTTON && trialsLeft.badgeButtonEnabled;
  const shouldRenderBadgeProduct = type === TrialsLeftType.BADGE_PRODUCT;
  const shouldRenderText = type === TrialsLeftType.TEXT && trialsLeft.textEnabled;

  return (
    <>
      {shouldRenderBadge || shouldRenderBadgeProduct || shouldRenderText ?
        (
          <div className={
            shouldRenderText ? classes.text
              : shouldRenderBadgeProduct ? classes.product_badge
                : classes.badge
          }>
            {t(`${tKey}.${shouldRenderText ? 'text' : 'badge'}`, { counter })}
          </div>
        ) : null}
    </>
  )
}

export default TrialsLeft;
