import React, { useRef, useReducer, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  useElements,
  CardElement as CardElementStripe,
} from '@stripe/react-stripe-js';

import { SUBMIT_BUTTON_COLOR } from 'src/constants/styles';

import CardElement from '../card-element';
import { PaymentSystem } from '../../constants';
import { getPaymentSystem } from '../../utils/storage';
import validate from '../../utils/validation';
import usePayment from '../../hooks/use-payment';

import styles from './styles.module.css';

const REQUIRED_RECURLY_FIELDS = ['number', 'month', 'year'];

const formReducer = (state, e) => {
  const { value } = e;
  return {
    ...state,
    [e.name]: {
      ...state[e.name],
      value,
      isValid: validate(value, e.name, 2),
      isTouched: e.touch,
    },
  };
};

const CardForm = ({
  isSimpleView,
  color,
  paymentSystem,
  product,
  onSubmit,
  onChange,
  onReady,
  onSuccess,
  onError,
  onApplePayClick,
  onApplePaySubmit,
  onGooglePayClick,
  onGooglePaySubmit,
  onUserInfoChange,
  onEvent,
  formClassName,
  buttonClassName,
  fieldClassName,
  buttonTitle,
  additionalFields,
  options,
  inputStyles,
  labelStyles,
  notEmptyLabelStyles,
  children,
  onValidateChildren,
}) => {
  paymentSystem = paymentSystem || getPaymentSystem();

  const formRef = useRef();

  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isInvalidCardForm, setInvalidCardForm] = useState(false);

  const payment = usePayment();

  const elements =
    paymentSystem === PaymentSystem.STRIPE && useElements();

  if (paymentSystem === PaymentSystem.SOLIDGATE) {
    options = options || {};
    options.formParams = options.formParams || {};
    options.formParams.submitButtonText = buttonTitle;
    options.formParams.submitButtonColor = isSimpleView ? color : SUBMIT_BUTTON_COLOR;
    if (additionalFields?.cardholder) {
      options.formParams.isCardHolderVisible = true;
    }
    if (additionalFields?.zip) {
      options.formParams.allowedAdditionalFields = ['ZIP'];
      delete additionalFields?.zip;
    }
  }

  const initialState = Object.keys(additionalFields).reduce(
    (acc, key) => {
      if (key === 'cardholder') {
        return { ...acc };
      }
      return {
        ...acc,
        [key]: {
          value: '',
          ...additionalFields[key],
          isValid: validate(additionalFields[key].value || '', key),
          isTouched: false,
        },
      }
    },
    {}
  );

  const [fields, setFormData] = useReducer(formReducer, initialState);

  const handleInputChange = useCallback((e) => {
    e.preventDefault();
    const { value } = e.target;
    const { name } = e.target;

    setFormData({
      name,
      value,
      touch: true,
    });

    if (typeof onChange === 'function') onChange(e);
  }, []);

  const validateForm = () => {
    Object.keys(fields).map((key) => {
      setFormData({
        name: key,
        value: fields[key].value,
        touch: true,
      });
      return key;
    });
  };

  const handleSubmitError = (error) => {
    console.log('[SUBMIT ERROR]', error);

    if (error.fields) {
      error.fields.map((key) => {
        if (REQUIRED_RECURLY_FIELDS.includes(key) && !isInvalidCardForm) {
          setInvalidCardForm(true);
        }

        return key;
      });
    }

    if (error.type === 'validation_error' || error.code === 'validation') {
      setInvalidCardForm(true);
    }

    return true;
  };

  const handleSubmit = async (e) => {
    if (e.preventDefault) e.preventDefault();
    setSubmitted(true);
    setTimeout(() => setSubmitted(false), 1000);
    setLoading(true);
    const { id } = product;

    const formData = {
      price_id: id,
      email: fields.email?.value,
      first_name: document.querySelector('input[name=first_name]')?.value,
      last_name: document.querySelector('input[name=last_name]')?.value,
      method: 'card',
      paymentSystem,
    };

    validateForm();

    try {
      if (paymentSystem === PaymentSystem.RECURLY) {
        await payment.token(formRef.current, (error, token) => {
          const isValidatedChildren = children ? onValidateChildren() : true;

          if (error) {
            return handleSubmitError(error);
          }

          formData.token_id = token?.id;

          if (isValidatedChildren) {
            onSubmit(formData);
          }
        });
      } else if (paymentSystem === PaymentSystem.STRIPE) {
        const valuesAdditionalFields = Object.keys(fields).reduce(
          (acc, key) => ({
            ...acc,
            [key]: fields[key].value,
          }),
          {}
        );

        const cardElement = elements.getElement(CardElementStripe);
        const { error, paymentMethod } = await payment.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            ...valuesAdditionalFields,
          },
        });

        if (error) {
          return handleSubmitError(error);
        }

        const { isIntroductory, trialDays, onetimePriceId } = product;
        formData.payment_method_id = paymentMethod?.id;
        formData.trial_period_days = trialDays;
        if (isIntroductory && onetimePriceId) {
          formData.introductory_price_id = onetimePriceId;
        }

        onSubmit(formData);
      } else if (paymentSystem === PaymentSystem.SOLIDGATE) {
        onSubmit(formData);
      }
    } catch (error) {
      console.log('[SUBMIT ERROR]', error);
    } finally {
      setLoading(false);
    }
  };

  const handleApplePaySubmit = (e) => {
    onApplePaySubmit({
      ...e,
      paymentSystem,
    });
  };

  const handleGooglePaySubmit = (e) => {
    onGooglePaySubmit({
      ...e,
      paymentSystem,
    });
  };

  const handleCardElementChange = (e) => {
    if (isInvalidCardForm) {
      setInvalidCardForm(false);
    }

    if (typeof onChange === 'function') {
      onChange(e);
    }
  };

  const handleSuccess = (e) => {
    onSuccess({
      ...e,
      paymentSystem,
    });
  };

  const handleError = (err) => {
    onError({
      ...(err || {}),
      paymentSystem,
    });
  };

  const renderField = (key, attributes) => {
    const {
      label,
      isValid,
      isTouched,
      value,
      type,
      placeholder = '',
    } = attributes;
    return (
      <div
        key={key}
        className={classNames(
          styles.field,
          {
            invalid: !isValid && isTouched,
          },
          fieldClassName
        )}
      >
        <label htmlFor={`additional-field-${key}`}>{label}</label>
        <input
          id={`additional-field-${key}`}
          data-recurly={key}
          name={key}
          placeholder={placeholder}
          value={value}
          type={type}
          onChange={handleInputChange}
        />
      </div>
    );
  };

  const renderFields = () => {
    const fieldsArray = Object.keys(fields);

    if (!fieldsArray.length) {
      return null;
    }

    return fieldsArray.map((key) => renderField(key, fields[key]));
  };

  const fromClassNames = classNames(styles.form, formClassName);

  const cardClassNames = classNames(
    styles.field,
    { [styles.invalid]: isInvalidCardForm },
    { [styles.submitted]: submitted },
    fieldClassName
  );

  const buttonClassNames = classNames(
    styles.button,
    {
      disabled: isLoading,
    },
    buttonClassName
  );

  return (
    <form onSubmit={handleSubmit} className={fromClassNames} ref={formRef}>
      <CardElement
        paymentSystem={paymentSystem}
        product={product}
        options={options}
        className={cardClassNames}
        onSubmit={handleSubmit}
        onChange={handleCardElementChange}
        onReady={onReady}
        onSuccess={handleSuccess}
        onError={handleError}
        onApplePayClick={onApplePayClick}
        onApplePaySubmit={handleApplePaySubmit}
        onGooglePayClick={onGooglePayClick}
        onGooglePaySubmit={handleGooglePaySubmit}
        onUserInfoChange={onUserInfoChange}
        onEvent={onEvent}
        inputStyles={inputStyles}
        labelStyles={labelStyles}
        notEmptyLabelStyles={notEmptyLabelStyles}
      />

      {renderFields()}

      {children}

      {paymentSystem !== PaymentSystem.PADDLE && paymentSystem !== PaymentSystem.SOLIDGATE && (
        <button type='submit' disabled={isLoading} className={buttonClassNames}>
          <span>{buttonTitle}</span>
        </button>
      )}
    </form>
  );
};

CardForm.propTypes = {
  product: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onReady: PropTypes.func,
  onApplePayClick: PropTypes.func,
  onApplePaySubmit: PropTypes.func,
  onGooglePayClick: PropTypes.func,
  onGooglePaySubmit: PropTypes.func,
  onUserInfoChange: PropTypes.func,
  onEvent: PropTypes.func,
  formClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  fieldClassName: PropTypes.string,
  buttonTitle: PropTypes.string,
  additionalFields: PropTypes.object,
  options: PropTypes.object,
};

CardForm.defaultProps = {
  buttonTitle: 'Submit',
  additionalFields: {},
};

export default CardForm;
