import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Textfit } from 'react-textfit'

import { t, tm, TM } from '@web-solutions/module-localization';

import { remoteConfigSelector } from 'core/store/remote-config/selectors';
import { getSubTitleText, getProductTitle } from 'core/utils/products';
import { PriceAfterTrialType } from 'core/payment/constants';
import { ProductDetails } from 'core/store/billing/selectors';
import TrialsLeft, { TrialsLeftType } from 'core/payment/components/trials-left';

import { DiscountWithTimer } from '../discount-with-timer';
import { SmallBadge } from '../small-badge';
import { SoldLine } from '../sold-line';

import classes from './style.module.scss';

const tKey = 'core.plans';

interface ItemProps {
  isActive: boolean,
  product: ProductDetails,
  onClick: (product: ProductDetails) => void,
};

export const Item: React.FC<ItemProps> = ({
  isActive,
  product,
  onClick,
}) => {
  const {
    subTitle,
    weekPrice,
    priceText,
    period,
    isTrial,
    trialDays,
    trialPrice,
    textLineThrough,
    isIntroductory,
    accentTitle,
    accentSubTitle,
    accentTextLineThrough,
    sold,
    promocode,
    trialsLeftBadge,
  } = product;

  const { priceAfterTrialType } = useSelector(remoteConfigSelector);
  const promocodeActivated = useSelector((state: any) => state.billing.promocodeActivated)
  const priceForSubTitle = isTrial ? trialPrice : priceText;
  const titleText = getProductTitle(product);
  const subTitleText = getSubTitleText(subTitle, period, isTrial, isIntroductory, trialDays, priceForSubTitle);
  const afterTrialText = tm(
    product.afterTrialText,
    `${tKey}.after_trial.${period}`,
    {
      ...product,
      price:
        priceAfterTrialType === PriceAfterTrialType.WEEK
          ? `${weekPrice}/${t(`core.payment_popup.price_texts.P1W`)}`
          : priceText,
      interpolation: { escapeValue: false, },
    });
  const perPeriodTKey = period?.indexOf('D') === 2 ? 'per_day' : 'per_week';
  const perText = tm(accentSubTitle, `${tKey}.${perPeriodTKey}`, product);
  const perTitle = tm(accentTitle || weekPrice, '', product);

  const handleItemClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onClick(product);
  };

  const isSoldOut = !!sold?.total && !sold.left;

  const isLightColorPerWeek = !!accentTextLineThrough && !isActive;

  return (
    <div className={classNames(classes.wrap, { [classes.disabled]: isSoldOut })}>
      {trialsLeftBadge && <TrialsLeft type={TrialsLeftType.BADGE_PRODUCT} />}
      <DiscountWithTimer
        discount={product.discount}
        badgeTitle={tm(product.badgeTitle, isSoldOut ? 'core.plans.sold_out' : '')}
        centered={product.badgeCentered}
        disabled={isSoldOut}
      />
      <button
        type="button"
        onClick={handleItemClick}
        className={classNames(classes.container, { [classes.isActiveContainer]: isActive })}
        disabled={isSoldOut}
      >
        <div className={classes.detailWrap}>
          <p className={classes.productTitle}>
            {titleText}
            <SmallBadge
              texts={product.smallBadges}
              disabled={isSoldOut}
            />
          </p>
          {!sold?.hiddenSubTitles ? (
            <>
              <p className={classes.productSubTitle}>
                <TM k={subTitleText} defaultKey='' tOptions={product} />
                &nbsp;
                {textLineThrough && <span className={classes.lineThrough}>{tm(textLineThrough, '', product)}</span>}
              </p>
              {isTrial && (
                <p className={classes.afterTrialText}>
                  {afterTrialText}
                </p>
              )}
            </>
          ) : null}
          {sold?.total ? (
            <SoldLine
              isActive={isActive}
              left={sold.left}
              total={sold.total}
            />
          ) : null}
        </div>
        <div className={classes.accentWrap}>
          {promocodeActivated ?
            <div className={classes.promo_badge}>
              {t(`${tKey}.promo.discount`, { discount: promocode?.discount })}
            </div>
            :
            accentTextLineThrough
              ?
              <Textfit mode='single' min={15} max={17} className={classes.accentTextLineThrough}>
                {tm(accentTextLineThrough, '', product)}
              </Textfit>
              :
              null
          }
          <Textfit mode='single' min={17} max={20} className={classNames(classes.accentTitle, isLightColorPerWeek && classes.lightAccentTitle)}>
            {perTitle}
          </Textfit>
          <p className={classNames(classes.accentSubTitle, isLightColorPerWeek && !isActive && classes.lightAccentSubTitle)}>
            {perText}
          </p>
        </div>
      </button>
    </div>
  );
};
