import type { FC, ComponentProps } from 'react'
import classNames from "classnames"

import { T } from "@web-solutions/module-localization";

import classes from '../../style.module.scss';

import { tKey } from './constants'
import type { SimpleViewProps } from './simple-view'

type TotalPriceProps = Pick<SimpleViewProps, 'fullPrice' | 'actualPrice' | 'mode'> & ComponentProps<'div'>

export const TotalPrice: FC<TotalPriceProps> = ({ className, fullPrice, mode, actualPrice, ...props }) => (
  <div className={classNames(classes.row, className)} {...props}>
    <p className={classes.aboutItemBlackText}>
      <T k={`${tKey}.total.total`} />
    </p>
    <p className={classes.aboutItemBlackText}>
      {mode === 'short' && <span className={classes.full_price}>{fullPrice}</span>}
      {actualPrice}
    </p>
  </div>
)
