import { PaymentSystem } from '@web-solutions/react-billing';

import { ProductConfig } from 'core/constants/remote-config';

export function getProductsIds(products: ProductConfig[]): string[] {
  return products.map((p) => p.id).filter(i => !!i) as string[];
}

type Ids = 'recurlyId' | 'solidgateId' | 'paddleId' | undefined

type IdsMap = {
  [key in PaymentSystem]: Ids;
};

const IDS_MAP: IdsMap = {
  [PaymentSystem.RECURLY]: 'recurlyId',
  [PaymentSystem.SOLIDGATE]: 'solidgateId',
  [PaymentSystem.PADDLE]: 'paddleId',
  [PaymentSystem.STRIPE]: undefined,
  [PaymentSystem.PAYPAL]: undefined,
}

export function prepareProductsIds(products: ProductConfig[], paymentSystem: PaymentSystem): ProductConfig[] {
  return products.map(p => ({
    ...p,
    id: p[IDS_MAP[paymentSystem] || 'id'] as string || p.id,
  }));
}
