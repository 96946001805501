import classNames from 'classnames';

import { T, t, tm } from '@web-solutions/module-localization';
import { getCurrencySymbols, } from '@web-solutions/react-billing/utils/prices';
import { type PriceTypes } from '@web-solutions/core/constants/remote-config';

import { useRemoteConfig } from 'core/hooks/use-remote-config';
import TrialsLeft, { TrialsLeftType } from 'core/payment/components/trials-left';
import TrialPrice from 'core/payment/base/product-info/components/trial-price';
import { ProductDetails } from 'core/store/billing/selectors';
import { PriceAfterTrialType } from 'core/payment/constants';
import { createVATPrice } from 'core/utils/create-vat-price';

import classes from '../../style.module.scss';

interface PricesProps {
  product: ProductDetails;
  priceText: string;
}

const prevAmountTypes: Record<PriceTypes, 'prevPriceAmount' | 'prevWeekPriceAmount' | 'prevDayPriceAmount'> = {
  today: 'prevPriceAmount',
  week: 'prevWeekPriceAmount',
  day: 'prevDayPriceAmount',
}

const amountTypes: Record<PriceTypes, 'amount' | 'weekPriceAmount' | 'dayPriceAmount'> = {
  today: 'amount',
  week: 'weekPriceAmount',
  day: 'dayPriceAmount',
}

const tKey = 'core.payment_popup';

export const Prices: React.FC<PricesProps> = ({
  product,
  priceText,
}) => {

  const {
    showPriceDiscount,
    promocodeOffer,
    showAfterTrialPrice,
    totalPriceType,
    displayVAT,
    priceAfterTrialType,
  } = useRemoteConfig();

  const {
    title,
    currency,
    textLineThrough,
    period,
    discount,
    isTrial,
    trialDays,
    trialPrice,
    aboutTrialText,
    savedPrice,
    promocode
  } = product;

  const amountLineThrough = textLineThrough ? product[prevAmountTypes[totalPriceType]] : '';

  const { currencySuffix, currencySymbol } = getCurrencySymbols(currency);
  const textLineThroughByPeriod = `${currencySymbol}${amountLineThrough}${currencySuffix}`

  const totalPriceKey = isTrial ? 'today' : totalPriceType;

  const amount = product[amountTypes[totalPriceType]];

  const { price: priceLineThrough } = createVATPrice(amountLineThrough, currency);

  const { vat, price } = createVATPrice(amount, currency);

  const priceString = displayVAT ? price : priceText;
  const todayPrice = !isTrial ? priceString : trialPrice;

  return <>
    <div className={classes.block}>
      {showPriceDiscount && (
        <div className={classes.row}>
          <p className={classes.aboutItemText}>
            {tm(title, '')}
          </p>
          <div className={classes.leftColumn}>
            <span className={classNames(classes.lineThrough, classes.disabled)}>
              {tm(textLineThrough, '', product)}
            </span>
          </div>
        </div>
      )}
      {showPriceDiscount && !!discount && !!savedPrice && (
        <div className={classes.row}>
          <p className={classes.aboutItemText}>
            <b>{t(`${tKey}.personal_discount`, { discount: discount })}</b>
          </p>
          <div className={classes.leftColumn}>
            <b>{tm(savedPrice, '', product)}</b>
          </div>
        </div>
      )}
      {isTrial && (
        <>
          <div className={classes.row}>
            <p className={classes.aboutItemText}>
              {tm(aboutTrialText, `${tKey}.trial`, { days: trialDays })}
            </p>
            <div className={classes.leftColumn}>
              <TrialsLeft type={TrialsLeftType.TEXT} />
              <TrialPrice
                textLineThrough={!showPriceDiscount ? tm(textLineThrough, '', product) : ''}
                trialPrice={trialPrice}
              />
            </div>
          </div>
          {showAfterTrialPrice && (
            <div className={classes.row}>
              <p className={classes.aboutItemText}>{t(`${tKey}.after`)}</p>
              <p className={classes.aboutItemText}>
                {
                  priceAfterTrialType === PriceAfterTrialType.WEEK
                    ? t(`${tKey}.after_trial.${period}`, product)
                    : `${priceText}/${t(`${tKey}.price_texts.${period}`)}`
                }
              </p>
            </div>
          )}
        </>
      )}
      {promocode?.discount && promocode?.discountPrice && promocodeOffer?.showPaymentModalDiscount &&
        <div className={classes.promo_discount}>
          <p>
            {t(`${tKey}.promocode.discount`, { discount: promocode?.discount })}
          </p>
          <p className={classes.discount_price}>
            {`-${currencySymbol}${promocode?.discountPrice}${currencySuffix}`}
          </p>
        </div>
      }
      <div className={classes.row}>
        <p className={classes.aboutItemBlackText}>
          <T
            k={`${tKey}.total.${totalPriceKey || 'today'}`}
            components={{ span: <span className={classes.smallText} /> }}
          />
        </p>
        <p className={classes.aboutItemBlackText}>
          {!isTrial && !!textLineThrough && !showPriceDiscount && <span className={classes.lineThrough}>{displayVAT ? priceLineThrough : textLineThroughByPeriod}</span>}
          {todayPrice}
        </p>
      </div>
      {!isTrial && displayVAT && <div className={classes.row}>
        <p className={classes.vatText}>{t(`${tKey}.vat`)}</p>
        <p className={classes.vatText}>
          {vat}
        </p>
      </div>}
    </div>

    <div className={classes.divider} />
  </>
}