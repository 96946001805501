export const PREFIX_WITH_PAYMENT = '-pp';

export const PROJECTS_TYPES = {
  QUIZ: 'quiz',
  QUIZ_PP: `quiz${PREFIX_WITH_PAYMENT}`,
  FLOW: 'flow',
  FLOW_PP: `flow${PREFIX_WITH_PAYMENT}`,
  TERMINATE: 'terminate',
  MANAGE: 'manage',
} as const;

export type projectsTypes = typeof PROJECTS_TYPES[keyof typeof PROJECTS_TYPES];

export const EVENT_ACTION = {
  OPEN: 'open',
  SUCCESS: 'success',
  ERROR: 'error',
  CLOSE: 'close',
  SKIP: 'skip',
  CLICK: 'click',
  SUBMIT: 'submit',
  CANCEL: 'cancel'
};
