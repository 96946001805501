import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { PaymentSystem, SolidgatePayPalButton } from '@web-solutions/react-billing';
import Analytics from '@web-solutions/module-analytics';

import { selectOrdersPayPal, type ProductDetails } from 'core/store/billing/selectors';
import { type Purchase } from 'core/interfaces/billing';

import { Preloader } from 'core/ui-elements';

const eventsCategory = 'paypal_solid';

interface Props {
  product: ProductDetails,
  onSuccess: (p: Purchase) => void,
  onError: (e: any) => void,
  className?: string,
  style?: any,
}

const PayPalBtn: React.FC<Props> = ({
  product,
  onSuccess,
  onError,
  className,
  style,
}) => {
  const ordersPayPal = useSelector(selectOrdersPayPal);

  const [pending, setPending] = useState(false);

  const handleClick = useCallback(() => {
    Analytics.trackEvent(eventsCategory, 'click');
    setPending(true);
  }, [])

  const handleSubmit = useCallback(() => {
    Analytics.trackEvent(eventsCategory, 'submit');
    Analytics.trackEvent('modal_payment', 'submit', {
      method: 'paypal-vault',
      paymentSystem: PaymentSystem.SOLIDGATE,
      productId: product?.id,
      value: +product?.trialPriceAmount || +product?.amount,
      currency: product?.currency,
    });
  }, [product?.amount, product?.currency, product?.id, product?.trialPriceAmount])

  const handleSuccess = useCallback((p: any) => {
    Analytics.trackEvent(eventsCategory, 'success');
    onSuccess(p);
    setPending(false);
  }, [onSuccess])

  const handleCancel = useCallback(() => {
    Analytics.trackEvent(eventsCategory, 'cancel');
    setPending(false);
  }, [])

  const handleError = useCallback((e: any) => {
    Analytics.trackEvent(eventsCategory, 'error', e);
    setPending(false);
    onError(e);
  }, [onError])

  return (
    <>
      <SolidgatePayPalButton
        productId={product?.id}
        orders={ordersPayPal}
        className={className}
        style={style}
        onClick={handleClick}
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
        onCancel={handleCancel}
        onError={handleError}
      />
      {pending && <Preloader />}
    </>
  );
};

export default PayPalBtn;
