import type { FC, PropsWithChildren, ComponentProps } from 'react';
import classNames from 'classnames';

import classes from './style.module.scss';

const Text: FC<PropsWithChildren<ComponentProps<'p'>>> = ({ children, className, ...props }) => {
  const textStyle = classNames([classes.text, className]);

  return <p className={textStyle} {...props}>{children}</p>;
};

export default Text;
