import { ImagesMap } from 'core/interfaces/images';

import DARK_CARD_BG_1X from './dark/card-bg.webp';
import DARK_CARD_BG_2X from './dark/card-bg@2x.webp';
import DARK_CARD_BG_3X from './dark/card-bg@3x.webp';

import DARK_BILLING_BG_1X from './dark/billing-bg.webp';
import DARK_BILLING_BG_2X from './dark/billing-bg@2x.webp';
import DARK_BILLING_BG_3X from './dark/billing-bg@3x.webp';

import CARD_BG_1X from './light/card-bg.webp';
import CARD_BG_2X from './light/card-bg@2x.webp';
import CARD_BG_3X from './light/card-bg@3x.webp';

import BILLING_BG_1X from './light/billing-bg.webp';
import BILLING_BG_2X from './light/billing-bg@2x.webp';
import BILLING_BG_3X from './light/billing-bg@3x.webp';


import CARD_1X from './dark/card.webp';
import CARD_2X from './dark/card@2x.webp';
import CARD_3X from './dark/card@3x.webp';

import IMAGE_CARD_1X from './dark/image-card.webp';
import IMAGE_CARD_2X from './dark/image-card@2x.webp';
import IMAGE_CARD_3X from './dark/image-card@3x.webp';

export const IMAGES: ImagesMap = {
  //dark theme
  DARK_CARD_BG: {
    src: DARK_CARD_BG_1X,
    srcSet: `${DARK_CARD_BG_1X}, ${DARK_CARD_BG_2X} 2x, ${DARK_CARD_BG_3X} 3x`,
  },
  DARK_BILLING_BG: {
    src: BILLING_BG_1X,
    srcSet: `${DARK_BILLING_BG_1X}, ${DARK_BILLING_BG_2X} 2x, ${DARK_BILLING_BG_3X} 3x`,
  },
  CARD: {
    src: CARD_1X,
    srcSet: `${CARD_1X}, ${CARD_2X} 2x, ${CARD_3X} 3x`,
  },
  IMAGE_CARD: {
    src: IMAGE_CARD_1X,
    srcSet: `${IMAGE_CARD_1X}, ${IMAGE_CARD_2X} 2x, ${IMAGE_CARD_3X} 3x`,
  },
  //light theme
  CARD_BG: {
    src: CARD_BG_1X,
    srcSet: `${CARD_BG_1X}, ${CARD_BG_2X} 2x, ${CARD_BG_3X} 3x`,
  },
  BILLING_BG: {
    src: BILLING_BG_1X,
    srcSet: `${BILLING_BG_1X}, ${BILLING_BG_2X} 2x, ${BILLING_BG_3X} 3x`,
  },
};