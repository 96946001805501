export const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

export const COLORS = {
  GREEN: '#16786C',
  LIGHT_GREEN1: '#D9EAE8',
  LIGHT_GREEN2: '#F4FDFC',
  ORANGE: '#ee8f61',
  WHITE: '#ffffff',
};

export const APP_NAME = process.env.REACT_APP_TITLE;
