import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { InjectedPaymentMethod } from 'core/constants/remote-config';
import { useRemoteConfig } from 'core/hooks/use-remote-config';
import { setInjectedPaymentMethod } from 'core/store/billing/actions';
import { useShowPayPal, useShowApplePay, useShowGooglePay } from 'core/hooks/use-show-payment-method';

import { selectInjectedPaymentMethod } from 'core/store/billing/selectors';

import { ReactComponent as Check } from './icons/check.svg';

import classes from './style.module.scss';

interface Props {
  isBaseSwitch?: boolean;
}

const InjectedPaymentMethodSwitch: React.FC<Props> = ({ isBaseSwitch = false }) => {
  const { defaultInjectedPaymentMethod, injectedPaymentMethods } = useRemoteConfig();
  const injectedPaymentMethod = useSelector(selectInjectedPaymentMethod);

  const dispatch = useDispatch();
  const showPayPal = useShowPayPal();
  const showApplePay = useShowApplePay();
  const showGooglePay = useShowGooglePay();

  const methods = {
    [InjectedPaymentMethod.APPLEPAY]: { show: showApplePay },
    [InjectedPaymentMethod.PAYPAL]: { show: showPayPal },
    [InjectedPaymentMethod.CARD]: { show: true },
    [InjectedPaymentMethod.GOOGLEPAY]: { show: showGooglePay },
    [InjectedPaymentMethod.GOOGLEPAY_PAYPAL]: { show: showGooglePay || showPayPal },
    [InjectedPaymentMethod.APPLEPAY_PAYPAL]: { show: showApplePay || showPayPal },
    [InjectedPaymentMethod.APPLEPAY_GOOGLEPAY]: { show: showApplePay || showGooglePay },
  };

  useEffect(() => {
    if (!injectedPaymentMethod) {
      dispatch(setInjectedPaymentMethod(defaultInjectedPaymentMethod));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const availableMethods = injectedPaymentMethods.filter((id) => methods[id].show !== false);

    if (!availableMethods.includes(defaultInjectedPaymentMethod)) {
      dispatch(setInjectedPaymentMethod(availableMethods[0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showApplePay, showPayPal, showGooglePay, defaultInjectedPaymentMethod, dispatch, injectedPaymentMethods])

  const handleButtonClick = (id: string) => {
    dispatch(setInjectedPaymentMethod(id));
  }

  const getButton = (id: InjectedPaymentMethod) => {
    switch (id) {
      case InjectedPaymentMethod.GOOGLEPAY_PAYPAL:
        return (
          <div className={classes.imgContainer}>
            <div className={classes.container}>
              {showGooglePay && <div className={classes[`${InjectedPaymentMethod.GOOGLEPAY}-base`]} />}
              {showPayPal && <div className={classNames(classes[`${InjectedPaymentMethod.PAYPAL}-base`], classes.bottom)} />}
            </div>
          </div>
        );
      case InjectedPaymentMethod.APPLEPAY_PAYPAL:
        return (
          <div className={classes.imgContainer}>
            <div className={classes.container}>
              {showApplePay && <div className={classes[`${InjectedPaymentMethod.APPLEPAY}-base`]} />}
              {showPayPal && <div className={classNames(classes[`${InjectedPaymentMethod.PAYPAL}-base`], classes.bottom)} />}
            </div>
          </div>
        );
      case InjectedPaymentMethod.APPLEPAY_GOOGLEPAY:
        return (
          <div className={classes.imgContainer}>
            <div className={classes.container}>
              {showApplePay && <div className={classes[`${InjectedPaymentMethod.APPLEPAY}-base`]} />}
              {showGooglePay && <div className={classNames(classes[`${InjectedPaymentMethod.GOOGLEPAY}-base`], classes.bottom)} />}
            </div>
          </div>
        );
      default:
        return (
          <div className={classes.imgContainer}>
            <div className={classes.imgContainer}>
              {!isBaseSwitch && <div className={classes[`${id}-dark`]} />}
              <div className={isBaseSwitch ? classes[`${id}-base`] : classes[id]} />
            </div>
          </div>
        );
    }
  }

  return (
    <div className={classNames(classes.wrapper, isBaseSwitch && classes.base)}>
      {
        injectedPaymentMethods.map((id) => {
          const { show } = methods[id];

          return show && (
            <div
              key={id}
              className={classNames(classes.method, { [classes.active]: injectedPaymentMethod === id })}
              onClick={() => handleButtonClick(id)}
            >
              <div className={classes.check}>
                <Check className={classes.icon} />
              </div>
              {getButton(id)}
            </div>
          )
        })
      }
    </div>
  );
}

export default InjectedPaymentMethodSwitch;
