import React, { useState } from 'react';
import ReactModal from 'react-modal';
import classNames from 'classnames';

import classes from './style.module.scss';
import './style.scss';

if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#root');

interface Props {
  children: React.ReactNode;
  isOpen: boolean;
  centered?: boolean;
  onCloseModal?: () => void;
  className?: string;
  overlayClassName?: string;
}

const Modal: React.FC<Props> = ({ children, isOpen, centered, onCloseModal, className, overlayClassName }) => {
  const [wasOpen, setWasOpen] = useState(false);
  const removeBodyClass = (elementClass: string) => document.body.classList.remove(elementClass);

  const handleOpenModal = () => {
    setTimeout(() => {
      setWasOpen(true);
    }, 0);
  };

  const handleAfterClose = () => {
    setTimeout(() => {
      setWasOpen(false);
      removeBodyClass('ReactModal__Body--open');
    }, 0);
  };

  return (
    <ReactModal
      overlayClassName={classNames(classes.overlay, overlayClassName)}
      className={{
        base: classNames(classes.modal, className, { [classes.centered]: centered }),
        afterOpen: classNames({
          [classes['modal-content-after-open']]: wasOpen,
        }),
        beforeClose: classNames(classes['modal-content-before-close']),
      }}
      htmlOpenClassName='ReactModal__Html--open'
      onAfterOpen={handleOpenModal}
      onAfterClose={handleAfterClose}
      closeTimeoutMS={500}
      isOpen={isOpen}
      onRequestClose={onCloseModal}
    >
      {children}
    </ReactModal>
  );
};

export default Modal;
