import { type FC, Fragment } from 'react';
import classNames from 'classnames';

import Analytics from '@web-solutions/module-analytics';
import { t } from '@web-solutions/module-localization';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';
import type { PolicyLinks } from '@web-solutions/core/constants/remote-config';

import classes from './style.module.scss';

const tKey = 'core.agreement';

const POLICY_TEXT_CONFIG = ['policy', 'privacy_policy', 'terms', 'billing_terms', 'and', 'money_back'] as const

const POLICY_TEXT_TYPE_LOOKUP: PolicyTextLookup<'link' | 'text'> = {
  privacy_policy: 'link',
  terms: 'link',
  billing_terms: 'link',
  money_back: 'link',
  policy: 'text',
  and: 'text'
}

type PolicyTextLookup<T extends string = string> = Record<typeof POLICY_TEXT_CONFIG[number], T>

interface PolicyTextProps {
  policyLinks: PolicyLinks
}

export const PolicyText: FC<PolicyTextProps> = ({ policyLinks }) => {
  const policyLinksLookup: Partial<PolicyTextLookup> = {
    privacy_policy: policyLinks.privacy,
    terms: policyLinks.termsOfUse,
    billing_terms: policyLinks.billingTerms,
    money_back: policyLinks.moneyBack,
  };

  const handleLinkClick = (link?: string) => () => {
    Analytics.trackEvent('sub_main_payment_policy', EVENT_ACTION.CLICK, { link });
  };

  return (
    <div className={classes.confirmation}>
      <p className={classNames(classes.text, classes.confirmation__text)}>
        {POLICY_TEXT_CONFIG.map((key) =>
          <Fragment key={key}>
            {
              POLICY_TEXT_TYPE_LOOKUP[key] === 'link'
                ?
                <a
                  target="__blank"
                  href={policyLinksLookup[key]}
                  className={classNames(classes.text, classes.confirmation__link)}
                  onClick={handleLinkClick(policyLinksLookup[key])}
                >
                  {t(`${tKey}.${key}`)}
                </a>
                :
                t(`${tKey}.${key}`)
            }&nbsp;
          </Fragment>
        )}
      </p>
    </div >
  );
};
