import axios from 'axios';

const isLogEnabled = process.env.REACT_APP_NETWORK_LOGGING === 'true';

let apiAuth = '';


export const setApiAuth = (auth: string) => {
  apiAuth = auth;
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=UTF-8',
  },
});

instance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = apiAuth;
    config.url = `${config.baseURL}${config.url}`;
    if (isLogEnabled) console.log('Network Request:', `${config.url}`, config.method);
    return config;
  },
  async (error) => {
    if (isLogEnabled) console.error('Network Request:', error);
    throw error;
  },
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => response.data,
  async (error) => {
    if (isLogEnabled) console.error('Network Response:', error);
    const r = error?.response;
    const d = r?.data?.data || r?.data;
    if (d?.message) {
      const cause = Error(JSON.stringify(d, null, 2));
      cause.name = 'Server Error';
      const e = Error(d.message, { cause }) as (Error & { status: any, code: any, data: any });
      e.name = d.name;
      e.status = d.status;
      e.code = d.code;
      e.data = d;
      throw e;
    }
    throw error;
  },
);

export default instance;
