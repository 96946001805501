
import queryString from 'query-string';
import { createBrowserHistory } from 'history';

import DeviceProps from '@magnus/react-native-device-props';


export function parseUrlParams() {
  const p = queryString.parse(window.location.search);
  const pType = window.location.pathname.split('/')[1];
  if (p.idfm) {
    try {
      window.localStorage.setItem('@RNMV/IDFM', p.idfm);
    }
    catch (ex) { console.warn(ex); }
  } else if (pType !== 'terminate') {
    DeviceProps.getIDFM()
      .then(idfm => {
        p.idfm = idfm;
        createBrowserHistory().replace(window.location.pathname + '?' + queryString.stringify(p));
      });
  }
  return p;
}

export function stringifyUrlParams(params) {
  const p = queryString.parse(window.location.search);
  createBrowserHistory().replace(window.location.pathname + '?' + queryString.stringify({ ...p, ...params }));
}

export const urlToFile = async (url, filename, mimeType) => {
  const res = await fetch(url);
  const buf = await res.arrayBuffer();
  return new File([buf], filename, { type: mimeType });
};
